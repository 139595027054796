<template> 
 <div class="v-simple-cropper"> 
 <slot> 
 <button @click="upload">上传图片</button> 
 </slot> 
 <input class="file" ref="file" type="file" accept="image/*" @change="uploadChange"> 
 <div class="v-cropper-layer" ref="layer"> 
 <div class="layer-header"> 
 <button class="cancel" @click="cancelHandle">取消</button> 
 <button class="confirm" @click="confirmHandle">裁剪</button> 
 </div> 
 <img ref="cropperImg"> 
 </div> 
 </div> 
</template> 
 <script> 
import Cropper from 'cropperjs' 
import 'cropperjs/dist/cropper.min.css' 
export default { 
 name: 'v-simple-cropper', 
 props: { 
 initParam: Object, 
 successCallback: { 
    type: Function, 
    default: () => {} 
    } 
 }, 
 data () { 
  return { 
      cropper: {}, 
      filename: '' 
      } 
  }, 
  mounted () { 
      this.init();
  }, 
 methods: { 
    // 初始化裁剪插件 
    init () { 
        let cropperImg = this.$refs['cropperImg'] 
        this.cropper = new Cropper(cropperImg, { 
        aspectRatio: 1 / 1, 
        dragMode: 'move' 
        }) 
    }, 
    // 点击上传按钮 
    upload () { 
        this.$refs['file'].click() 
    }, 
    // 选择上传文件 
    uploadChange (e) { 
        let file = e.target.files[0] 
        this.filename = file['name'] 
        let URL = window.URL || window.webkitURL 
        this.$refs['layer'].style.display = 'block' 
        this.cropper.replace(URL.createObjectURL(file)) 
    }, 
    // 取消上传 
    cancelHandle () { 
        this.cropper.reset() 
        this.$refs['layer'].style.display = 'none' 
        this.$refs['file'].value = '' 
    }, 
    // 确定上传 
    confirmHandle () { 
        let cropBox = this.cropper.getCropBoxData() 
        let scale = this.initParam['scale'] || 1 
        let cropCanvas = this.cropper.getCroppedCanvas({ 
        width: cropBox.width * scale, 
        height: cropBox.height * scale 
        }) 
        let imgData = cropCanvas.toDataURL('image/jpeg') 
        
        // let formData = new window.FormData() 
        //   formData.append('fileType', this.initParam['fileType']) 
        //   formData.append('img', imgData) 
        //   formData.append('originalFilename', this.filename) 
        //   window.$axios(this.initParam['uploadURL'], formData, { 
        //   method: 'post', 
        //   headers: {'Content-Type': 'multipart/form-data'} 
        //   }).then(res => { 
         this.successCallback(this.dataURLtoFile(imgData,'avt.jpg')); 
         this.cancelHandle();
        //  }) 
    },
    dataURLtoFile(url, filename) {
      var arr = url.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), i = bstr.length, u8arr = new Uint8Array(i);
      while(i--){
          u8arr[i] = bstr.charCodeAt(i);
      }
     return new File([u8arr], filename, {type:mime});
  }
 } 
} 
</script> 
  
<style lang="less"> 
.v-simple-cropper { 
 .file { 
    display: none; 
 } 
 .v-cropper-layer { 
    position: fixed; 
    top: 0; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    background: #fff; 
    z-index: 99999; 
    display: none; 
 .layer-header { 
  position: absolute;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 4rem;
    /* padding: 0 0.2rem; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
 } 
  .cancel, 
  .confirm { 
     font-size:1rem;
    background: inherit;
    border: 0;
    outline: 0;
    float: left;
    background: rgba(255,255,255,0.8);
    height: 2rem;
    border-radius: 0.3rem;
    width: 5rem;
  } 
  .confirm { 
      float: right; 
  } 
  img { 
    position: inherit!important; 
    border-radius: inherit!important; 
    float: inherit!important; 
  } 
 } 
} 
</style> 