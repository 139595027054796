<template>
 <div id="placeorder">
    <headerbar leftvisible="true" title="设置个人信息"/>
        <div class="submit-pay">
            <div class="form-group">
                <div class="form-label">我的头像</div>
                <div class="form-control" style="min-height:40px">
                    <cropper class="photo-warpper" :initParam="uploadParam" :successCallback="uploadHandle" ref="cropper"> 
                        <img class="photo" :src="Photo" @click="upload"> 
                        <img class="photo-selecter" @click="upload" src="@/assets/icon/share5.png"/>
                    </cropper> 
                </div>
            </div>
            <div class="form-group">
                <div class="form-label">我的昵称</div>
                <div class="form-control" style="min-height:40px">
                    <input type="text" class="form-input" v-model="NickName"  placeholder="您的昵称"/>
                </div>
            </div>
        </div>
         <footerbar>
            <div class="toolbar">
                <div class="btn btn-primary" style="width:50vw" v-on:click="save">保存</div>
            </div>
        </footerbar>
    </div>
</template>
<script>
import http from '../utils/http'
import { mapState, mapAcAtions, mapMutations } from 'vuex'
import UserService from '../services/UserService'
import cropper from "../components/cropper.vue"
export default {
    name: 'placeorder',
    components: {
        cropper
    },
    computed: {
        ...mapState({
            isLogin: state => state.user.isLogin,
            userName: state => state.user.userName,
            token: state => state.user.token,
            photo: state => state.user.photo,
            nickName: state => state.user.nickName,
        }),
    },
    data () {
        return {
            NickName:'',
            Profile:'',
            UserName:'',
            Profile:'',
            Photo:'',
            uploadParam: { 
                fileType: 'recruit', // 其他上传参数 
                scale: 4 // 相对手机屏幕放大的倍数: 4倍 
            }, 
            userImg: this.$dataURL + 'test.png' 
        } 
    },
    methods: {
        // 上传头像 
        upload () { 
            this.$refs['cropper'].upload() 
        }, 
        // 头像回调 
        uploadHandle (imgFile) { 
            if (imgFile) { 
                debugger
                let param = new FormData(); //创建form对象
                param.append('file', imgFile, imgFile.name);//通过append向form对象添加数据
                param.append('chunk', '0');//添加form表单中其他数据
                UserService.UploadPhoto(param).then(res => {
                    if (res.Success) {
                       this.Photo=res.Data[0].Url;
                    }
                })
            } 
        },
        newHeaderImage(newImg){
            this.Photo = newImg;
        },
        save: function () {
            var that=this;
            var _data={
                NickName:this.NickName,
                UserName:this.UserName,
                Profile:this.Profile,
                Photo:this.Photo
            };
            UserService.UpdateUserInfo(_data).then(s=>{
                    if (s.Success) {
                        that.$store.dispatch('user/getUserInfo');
                        history.back();
                    }
                    else{
                        alert(s.Message);
                    }
            }).catch(e=>{
                alert(e);
            });
        },
    },
    created: function () {
        
    },
    mounted: function () {//dom已渲染
        if(!this.isLogin){
            this.$router.push({ name: 'login'})
        }
        else{
            UserService.GetUserInfo().then(data=>{
                debugger
                this.NickName=data.Data.NickName;
                this.UserName=data.Data.UserName;
                this.Profile=data.Data.Profile;
                this.Photo=data.Data.Photo;
            });
        }
    },
}
</script>
<style lang="scss" scoped>

    @import url("/style/css/common.css");
    .photo-warpper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .photo-warpper .photo{
        width:80px;
        height:80px;
        border-radius: 40px;
        background: #fff;
        display: inline-block;
        vertical-align: middle;
        object-fit: cover;
        border: 1px solid #ddd;
        border-radius: 50%;
        margin-bottom: 10px;
     }
     .photo-warpper .photo-selecter{
        top: 30px;
        margin-left: 10px;
        width: 22px;
     }
   .form-input{
        outline-style: none ;
        border: 1px solid #eee;
        border-radius: 3px;
        padding: 10px 0px;
        width: 100%;
        font-size: 14px;
        text-indent: 10px;
        // font-family: "Microsoft soft";
   }
   .form-input:focus{
        border-color: #ff3333;
        outline: 0;
    }
   .form-group{
        width: 100%;
        height: auto;
        background: #eee;
        border-bottom: 1px solid #eee;
    }
    .form-label{
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color:#666;
        padding: 10px 4%;
        vertical-align: middle;
        box-sizing: border-box;
    }
    .form-control{
        font-size:14px;
        vertical-align: middle;
        color:#333;
        padding: 10px 4%;
        // border:1px solid #ccc;
        background: #fff;
        box-sizing: border-box;
        box-shadow: none;
        min-height: 38px;
    }
.toolbar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.toolbar .price{
   width: 131px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.toolbar .price .money{
    color:#ff3333;
    font-weight: 600;
}
.magic-radio:checked + label:after{
    display: block;
}
.magic-radio + label:after {
    top: 12px;
    left: 6px;
    box-sizing: border-box;
    width: 6px;
    height: 8px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
}
.magic-radio + label:after{
    position: absolute;
    display: none;
    content: '';
}
</style>
